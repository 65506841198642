import React, { lazy, Suspense } from "react";
import {
  Router,
  Redirect,
  Switch,
  Route,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import "./App.css";
import "semantic-ui-css/semantic.min.css";
import "video.js/dist/video-js.css";
import "@videojs/themes/dist/city/index.css";
import LoadingSpinner from "./Components/Loader/pageLoader";

const history = createBrowserHistory();

const PublishPage = lazy(() => import("./Pages/Publish"));
const PlayLiveVideo = lazy(() => import("./Pages/PlayLiveVideo")); // Using Websocket
const PlaySavedVideo = lazy(() => import("./Pages/PlaySavedVideo")); // Using CDN
const WatchLivePage = lazy(() => import("./Pages/WatchLive")); // Live Videos
const WatchSavePage = lazy(() => import("./Pages/WatchSave"));

const LoadingFallback = () => (
  <LoadingSpinner
    dimmerActive
    inline="centered"
    dimmerInverted
    message="Loading"
  />
);

const App = () => (
  <Suspense fallback={<LoadingFallback />}>
    <Router history={history}>
      <Switch>
        <Route exact path="/">
          <Redirect to="/home/:streamName" />
        </Route>
        <Route exact path="/home/:streamName">
          <PublishPage />
        </Route>
        <Route path="/play" exact>
          <PlayLiveVideo />
        </Route>
        <Route path="/watch/video/:streamName" exact>
          <PlaySavedVideo />
        </Route>
        <Route exact path="/watch/live">
          <WatchLivePage />
        </Route>
        <Route exact path="/watch/save">
          <WatchSavePage />
        </Route>
      </Switch>
    </Router>
  </Suspense>
);

export default App;
